









































































































































import Vue from "vue";
import { Group } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PublicGroupItem extends Vue {
  @Prop({ default: () => {} }) group!: Group;
  @Getter("groups/removeGroupLoading") removeGroupLoading!: boolean;
  @Getter("groups/removedGroupID") removedGroupID!: number;
  @Action("groups/removeGroup") removeGroupAction!: Function;
  @Action("groups/leaveGroup")
  leaveGroupAction!: (i: number) => Promise<void>;
  @Action("groups/joinGroup")
  joinGroupAction!: (i: number) => Promise<void>;
  @Action("displaySnackbar")
  displaySnackbar!: (m: string) => void;

  joinDialog = false;
  removeDialog = false;
  joining = false;

  // Group helpers
  get userEmails() {
    let emails = this.group.Users.map(u => u.Email);
    if (emails.length > 3) return emails.slice(0, 3).join(", ") + ", ...";
    else return emails.join(", ");
  }

  get owner() {
    console.log("get owner", this.group);
    const user = this.group.Users.find(x => x.IsOwner);
    return user ? user.Email : "";
  }

  // Delete group
  removeGroup() {
    if (this.removeGroupLoading) return;
    this.removeDialog = false;
    this.removeGroupAction(this.group.ID);
  }

  // join group
  async joinGroup() {
    this.joining = true;
    try {
      const msg = this.$t("groupItem.groupJoined", {
        name: this.group.Name,
      }).toString();
      await this.joinGroupAction(this.group.ID);
      this.displaySnackbar(msg);
    } catch (error) {
      console.log(error);
    }
    this.joinDialog = false;
    this.joining = false;
  }
}
