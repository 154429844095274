import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"add-group-card"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VSubheader,[_c('span',[_vm._v(_vm._s(_vm.$t("addGroupCard.addNewGroup")))]),_c(VSpacer),(_vm.addingGroup)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleAddGroup}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1),_c('div',{staticClass:"pa-4"},[(_vm.addingGroup)?_c(VForm,{staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.submitAddGroup($event)}}},[_c(VTextField,{attrs:{"label":_vm.$t('addGroupCard.groupName'),"required":"","rules":_vm.groupNameRules},model:{value:(_vm.newGroupName),callback:function ($$v) {_vm.newGroupName=$$v},expression:"newGroupName"}}),_c('EmailsInput',{attrs:{"label":_vm.$t('addGroupCard.users'),"next-user-prop":_vm.nextUser,"emails-prop":_vm.newUsers},on:{"update:nextUserProp":function($event){_vm.nextUser=$event},"update:next-user-prop":function($event){_vm.nextUser=$event},"update:emailsProp":function($event){_vm.newUsers=$event},"update:emails-prop":function($event){_vm.newUsers=$event}}}),_c(VSwitch,{attrs:{"label":_vm.$t('addGroupCard.publicGroup'),"hide-details":""},model:{value:(_vm.isPublic),callback:function ($$v) {_vm.isPublic=$$v},expression:"isPublic"}}),_c(VBtn,{staticClass:"mt-2",attrs:{"elevation":"0","block":"","type":"submit","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("addGroupCard.submit"))+" ")])],1):_vm._e(),(!_vm.addingGroup)?_c(VBtn,{attrs:{"color":"primary","loading":_vm.addGroupLoading,"disabled":_vm.addGroupLoading,"block":"","elevation":"0"},on:{"click":_vm.toggleAddGroup}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("addGroupCard.addGroup")))])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }